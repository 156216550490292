<template>
  <div fluid class="pa-0">
    <v-card class="test-plans-add-runs pt-6 px-6 mt-3" rounded="lg" elevation="0" width="100%">
      <div class="test-plans-add-runs-header">
        <div class="d-flex align-center">
          <button plain class="btn-nav-back font-inter" @click="handleBackClick($event)">
            <v-icon color="blue">mdi-chevron-left</v-icon>
            <span class="d-flex-inline justify-center align-center ma-0 blue--text">
              {{ $t('plans.create.backToCreatePlans') }}
            </span>
          </button>
          <button class="btn-close ml-auto" @click="handleBackClick($event)">
            <v-icon>mdi-close</v-icon>
          </button>
        </div>

        <h1 class="mt-4 font-inter text-start">{{ $t('testRuns') }}</h1>
        <div class="mt-4 d-flex">
          <v-chip
            class="font-inter font-weight-bold px-4"
            width="200px"
            label
            :class="{ 'blue--text': tableFilter === 'all' }"
            :color="tableFilter === 'all' ? 'blue-light' : 'gray-light'"
            @click="changeFilter('all')"
          >
            {{ $t('all') }} <span class="ml-2">{{ runs.length - selectedUserLength }}</span>
          </v-chip>
          <v-chip
            class="font-inter font-weight-bold px-4 ml-2"
            label
            width="200px"
            :class="{ 'blue--text': tableFilter === 'selected' }"
            :color="tableFilter === 'selected' ? 'blue-light' : 'gray-light'"
            @click="changeFilter('selected')"
            >
            {{ $t('selected') }} <span class="ml-2">{{ selectedUserLength }}</span>
          </v-chip>
        </div>
      </div>
      <div class="d-flex flex-column align-center mt-6" v-if="runs.length <= 0">
        <div class="d-flex flex-row justify-center">
          <v-img
            :src="require('@/assets/png/auth-banner.png')"
            alt="Table empty state"
            width="100%"
            max-width="438"
          />
        </div>
        <h2>{{ $t('plans.beginTestNewTitle') }}</h2>
        <p class="text-center" style="max-width:438px;">{{ $t('plans.beginTestNewDescripiton') }}</p>
        <v-btn class="text-capitalize primary px-6" depressed @click="openCreateTestRun">
          {{ $t('testruns.create_testrun.title') }}
          <v-icon size="16">mdi-plus</v-icon>
        </v-btn>
      </div>
      <div class="test-plans-add-runs-wrapper mt-4" v-else>
        <div class="search-container d-flex">
          <search-field class="search-input" v-model="searchFilter" :placeholder="$t('searchByName')" />
          <RunFilter @filters="applyFilters" />
          <v-menu left :close-on-content-click="false" :nudge-bottom="4" offset-y min-width="191">
            <template v-slot:activator="{ on }">
              <v-btn class="ml-auto pa-0 rounded-lg" height="40" min-width="40" elevation="0" v-on="on">
                <v-icon size="16px"> mdi-cog-outline </v-icon>
              </v-btn>
            </template>
            <v-list width="191" class="d-flex flex-column justify-space-between rounded-xl">
              <v-list-item class="font-inter custom-text-14 custom-menu-item my-2">{{ $t('columns') }}</v-list-item>
              <v-list-item v-for="(header, index) in filteredMenuHeaders" class="custom-menu-item" :key="index">
                <v-checkbox
                  v-model="header.isSelected"
                  :value="header.isSelected"
                  :true-value="true"
                  :false-value="false"
                  off-icon="mdi-checkbox-blank"
                  class="ma-0 pa-0"
                  color="blue"
                  :size="16"
                  hide-details
                >
                  <template v-slot:label>
                    <div class="custom-color-0c111d font-inter custom-text-14">{{ header.text }}</div>
                  </template>
                </v-checkbox>
              </v-list-item>
              <v-list-item class="custom-menu-item my-2" @click="handleColumnReset()">
                <div
                  class="custom-text-12 font-weight-semibold font-inter custom-color-d0d5dd"
                  :class="{ 'blue--text font-weight-semibold ': isColumnFilter }"
                >
                  {{ $t('Restore default') }}
                </div>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <test-run-list
          class="test-runs-list"
          :data="runs"
          :tab="tableFilter"
          :headers="filteredHeaders"
          :selectedRuns="selectedRunsLocal"
          @selectTestRun="selectTestRun"
          @addConfiguration="handleAddConfiguration"
        />
        <div class="actions-container d-flex justify-end py-4 pr-6">
          <v-menu v-model="actionMenu" :close-on-content-click="false" offset-y top right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="font-inter text-capitalize font-weight-bold black--text"
                v-bind="attrs"
                v-on="on"
                dark
                depressed
                color="gray-100"
              >
                {{ $t('actions') }} <v-icon>{{ 'mdi-chevron-down' }}</v-icon>
              </v-btn>
            </template>

            <v-list class="actions-list font-inter">
              <v-list-item class="actions-item" :to="{ name: 'TestPlanDuplicate' }">
                <v-list-item-title>{{ $t('plans.create.duplicate') }}</v-list-item-title>
              </v-list-item>
              <v-list-item class="actions-item" :to="{ name: 'TestPlanAddRuns' }">
                <v-list-item-title>{{ $t('plans.create.addTestRuns') }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-btn
            class="font-inter font-weight-bold text-none ml-3"
            dark
            depressed
            color="blue"
            :width="$vuetify.breakpoint.smAndDown ? '100%' : '150xp'"
            @click="handleCreateTestPlan"
          >
            {{ $t('plans.createTestPlan') }}
          </v-btn>
        </div>
      </div>
      <div class="d-flex flex-row justify-end py-4 mt-9" v-if="runs.length <= 0">
        <v-btn class="primary text-capitalize" depressed>{{ $t('plans.createTestPlan') }}</v-btn>
      </div>
    </v-card>
    <add-configuration-dialog :open="isOpenAddConfiguration" @close="isOpenAddConfiguration = false" />
  </div>
</template>
<script>
import SearchField from '@/components/Form/SearchField';
import TestRunList from '@/views/Tests/Plans/Components/TestRunList';
import AddConfigurationDialog from '@/views/Tests/Plans/Components/Configuration/Index';
import RunFilter from '@/components/TestRuns/RunFilter.vue';
import {
  runStateMap,
  TestPlansTestRunsTableHeaderAll,
  TestPlansTestRunsTableHeaderSelected,
} from '@/constants/grid.js';
import { createNamespacedHelpers, mapGetters, mapState } from 'vuex';

const { mapActions: mapRunActions } = createNamespacedHelpers('run');
const { mapMutations: mapPlanMutations } = createNamespacedHelpers('plan');

export default {
  components: {
    SearchField,
    TestRunList,
    RunFilter,
    AddConfigurationDialog,
  },
  data() {
    return {
      imageSrc: '',
      actionMenu: false,
      filter: 'ongoing',
      // testruns: [],
      form: {
        projectName: '',
        description: '',
        runs: [],
      },
      isColumnFilter: false,
      showAddUsersView: true,
      toBeSelectedUser: [],
      selectedRunsLocal: [],
      selectedUser: [],
      tableFilter: 'all',
      searchFilter: '',
      appliedFilters: {
        roles: [],
        projects: [],
        tags: [],
      },
      headers: TestPlansTestRunsTableHeaderAll,
      isOpenAddConfiguration: false,
    };
  },
  computed: {
    ...mapState('plan', ['selectedRuns']),
    ...mapState('run', ['items']),
    ...mapState('user', ['currentAccount']),
    ...mapGetters({
      currentOrg: 'user/currentAccount',
    }),
    runs() {
      let filtered = this.items.filter((item) => (item.customFields.archived === ( this.filter === 'ongoing' ? false : true )));
      const finalRuns = filtered.map((item) => {
        const createdFormat = new Date(item.createdAt);
        const createdAt = `${createdFormat.getFullYear()}-${String(createdFormat.getMonth()+1).padStart(2,'0')}-${new String(createdFormat.getDate()).padStart(2,'0')}`

        return {
          ...item,
          processedStatus: this.staticValues(item, runStateMap, 'New'),
          createdAt
        }
      });
      console.log(finalRuns, 'finalRuns');
      return finalRuns
    },
    imageStyle() {
      return this.imageSrc ? { backgroundImage: `url(${this.imageSrc})` } : {};
    },
    toBeSelectedUserLength() {
      return this.runs.filter((user) => user.toBeSelected).length;
    },
    selectedUserLength() {
      return this.runs.filter((user) => user.selected).length;
    },
    isAnyFilterApplied() {
      return this.appliedFilters.roles.length || this.appliedFilters.projects.length || this.appliedFilters.tags.length;
    },
    filteredHeaders() {
      const headers = this.tableFilter === 'all' ? TestPlansTestRunsTableHeaderAll : TestPlansTestRunsTableHeaderSelected;
      const filtered = headers.filter((x) => x.isSelected);
      if (filtered.length < headers.length)
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.isColumnFilter = true
      else
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.isColumnFilter = false
      return filtered;
    },
    filteredMenuHeaders() {
      const filtered = this.headers.filter((header) => header.text != 'Actions');
      return filtered;
    },
    filteredMilestones() {
      if (this.searchFilter && this.mainFilter == false) {
        const filtered = this.milestones.filter((item) => this.matchesFilter(item));
        return filtered.length > 0 ? filtered : 'SEARCH_BACKEND';
      }
      return this.milestones;
    },
    milestones() {
      let filtered = this.items.filter((item) => item.customFields.runstatus === this.filter);

      return filtered.map((item) => ({
        ...item,
        processedStatus: this.staticValues(item, runStateMap, 'New'),
      }));
    },
  },
  created() {
    let handle = this.currentAccount.handle;
    let projectKey = this.$route.params.key;
    this.getProjectRun(handle, projectKey);
    this.selectedRunsLocal = this.selectedRuns;
  },
  mounted() {
    this.runs.forEach((user) => {
      this.$set(user, 'toBeSelected', false);
    });
  },
  watch: {
    currentOrg: 'refreshData',
    filteredMilestones(newValue) {
      if (newValue === 'SEARCH_BACKEND') {
        this.searchRuns();
      }
    },
  },
  methods: {
    ...mapPlanMutations(['UPDATE_SELECTED_RUNS']),
    ...mapRunActions(['getAllTestRuns']),
    getProjectRun(handle, projectKey) {
      this.getAllTestRuns({ handle: handle, projectKey: projectKey, perPage: 99999, currentPage: 0 });
    },
    handleAddConfiguration(value) {
      this.isOpenAddConfiguration = true;
    },
    handleColumnReset() {
      this.headers = this.headers.map((header) => {
        header.isSelected = true;
        return header;
      })
    },
    handleCreateTestPlan() {
      this.UPDATE_SELECTED_RUNS(this.selectedRunsLocal);
      this.$router.replace({ name: 'TestPlanCreate' });
    },
    openFileDialog() {
      this.$refs.fileInput.click();
    },
    handleFileChange(event) {
      const file = event.target.files[0];
      this.previewImage(file);
    },
    handleDrop(event) {
      const file = event.dataTransfer.files[0];
      this.previewImage(file);
    },
    previewImage(file) {
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.imageSrc = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    },
    removeImage() {
      this.imageSrc = '';
    },
    handleBackClick(event) {
      event.preventDefault();

      this.$router.replace({ name: 'TestPlanCreate' });
    },
    closeShowAddUsersView() {
      this.showAddUsersView = false;
      this.toBeSelectedUser = [];
      this.$router.push({ name: 'MilestoneCreate' });
    },
    changeFilter(filter) {
      this.tableFilter = filter;
    },
    addUsers() {
      this.runs.forEach((user) => {
        if (user.toBeSelected) {
          this.$set(user, 'selected', true);
          this.$set(user, 'toBeSelected', false);
        }
      });
    },
    removeUsers() {
      this.runs.forEach((user) => {
        if (user.toBeSelected) {
          this.$set(user, 'selected', false);
          this.$set(user, 'toBeSelected', false);
        }
      });
    },
    selectTestRun(selectedRuns) {
     this.selectedRunsLocal = selectedRuns
    },
    clearAll() {
      this.appliedFilters = {
        roles: [],
        projects: [],
        tags: [],
      };
    },
    applyFilters(filters) {
      this.appliedFilters = filters;
    },
    staticValues(item, obj, defultValue) {
      return obj[item.customFields.state] || obj[defultValue];
    },
    matchesFilter(item) {
      const lowerCaseFilter = this.searchFilter.toLowerCase();

      const nameMatch = item.name.toLowerCase().includes(lowerCaseFilter);
      const sourceMatch = item.source.toLowerCase().includes(lowerCaseFilter);

      return nameMatch || sourceMatch;
    },
    async searchRuns() {
      if (this.searchFilter) {
        await this.$store.dispatch('run/searchTestRun', {
          handle: this.currentOrg.handle,
          text: this.searchFilter,
          perPage: this.perPage,
          currentPage: this.currentPage,
        });
      } else {
        this.refreshData();
      }
    },
    openCreateTestRun() {
      this.$router.push({
        name: 'TestRunCreate',
        params: {
          handle: this.$route.params.handle,
          key: this.$route.params.key
        },
        query: {
          page: 'TestPlanAddRuns'
        }
      });
    }
  },
};
</script>
  
<style lang="scss" scoped>
.test-plans-add-runs {
  position: relative;
  padding-bottom: 68px;
  height: calc(100vh - 90px - 8px);

  display: flex;
  flex-direction: column;
}

.test-plans-add-runs-header {
  flex: 0 1 138px;
}

.test-plans-add-runs-wrapper {
  flex: 1 1 auto;

  display: flex;
  flex-direction: column;
}

.search-container {
  flex: 0 1 40px;
}

.plans-list {
  flex: 1 1 auto;
  overflow: auto;
}

.btn-nav-back {
  width: max-content;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #0c2ff3 !important;
  text-transform: none;
  opacity: 1;

  display: flex;
  align-items: center;
}

.actions-container {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}

p{
  font-size: 14px;
  font-family: Inter;
}
</style>
